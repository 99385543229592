import { DownloadOutlined, PlusCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import SyncInProgressSpinner from 'components/Offline/SyncInProgressSpinner.js';
import { BorderedPanel } from 'components/Shared/PageParts.js';
import { CachedApiLoaderContext, CachedApiLoaderProvider } from 'components/Util/CachedApiLoader.js';
import { ZeroContext } from 'components/ZeroContext.js';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
    export_courses_analytics,
    export_form_analytics,
    get_form_drafts
} from '../../api/zero-api.js';
import '../../assets/css/antd-custom.css';
import {
    exportTableToXlsx,
    floatingButtonMargin,
    isAdmin,
    isContributor,
    isMobileApp,
    isTeamLead,
    isUser,
    isViewer,
    mainContentContainerClass,
    safe_get,
    scrollToTop
} from '../../other/Helper.js';
import NotificationAlert from "../../other/NotificationAlert";
import {
    updateFormsFilterQuery,
    updateFormsPagePosition,
    updateFormsPagination,
    updateFormsPeriodQuery,
    updateFormsTab
} from '../../store/actions/FormsActions';
import GenerateReport from '../AdminPages/Reports/GenerateReport.js';
import InfoIconComponent from '../InfoIconComponent';
import ButtonLoading from "../Shared/ButtonLoading";
import FormAnalyticsTab from './FormAnalyticsTab.js';
import FormAssignmentsTab from './FormAssignmentsTab.js';
import FormsSearchFilter from './FormsSearchFilter.js';
import MyDraftsModal from './MyDraftsModal.js';

import SubmissionSyncErrorAlertList from 'components/Offline/Forms/SubmissionSyncErrorAlertList.jsx';
import '../../assets/css/antd-custom.css';
import SelectFormModal from './SelectFormModal.js';
import { SubmissionDataGridContextProvider } from './SubmissionDataGrid/SubmissionDataGrid.context.jsx';
import SubmissionDataGrid from './SubmissionDataGrid/SubmissionDataGrid.jsx';
import { SubmissionDataGridModals } from './SubmissionDataGrid/SubmissionDataGrid.modals.jsx';
import { EventNames } from './SubmissionDataGrid/SubmissionDataGrid.utils.js';
import ResetSubmissionGridLink from './SubmissionDataGrid/ResetSubmissionGridLink.jsx';
import SubmissionDataGridExportButton from './SubmissionDataGrid/SubmissionDataGridExportButton.jsx';

class Forms extends Component {
    static contextType = ZeroContext;
    _isMounted = false;

    constructor(props) {
        super(props);

        /** @type {ZeroContext} */
        this.context;

        let pathname = safe_get(this.props, "location.pathname", "");

        this.state = {
            page_type: pathname.includes("courses") ? "lms" : "regular",
            isExportingAnalytics: false,
            formAssignmentsRefreshSignal: 0,
        };

        this.tableRef = React.createRef();

        this.editDraft = this.editDraft.bind(this);
        this.lastGetSubmissionsQuery = null;
        this.lastGetSubmissionsResult = null;
        this.lastGetDraftsQuery = null;
        this.lastGetDraftsResult = null;
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.props.dispatch(updateFormsPagePosition(window.pageYOffset));
        this.props.dispatch(updateFormsPagination(this.props.pagination));
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            scrollToTop("page-head");
            let page_type = safe_get(this.props, "location.pathname", "").includes("courses") ? "lms" : "regular";

            let tab = safe_get(this.props, "match.params.tab", "forms");
            this.props.dispatch(updateFormsTab(tab));

            this.props.dispatch(updateFormsPagination(1));
            this.props.dispatch(updateFormsPagePosition(0));
            this.setState({
                page_type: page_type,
            }, () => {
                this.getDrafts();
            });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        scrollToTop("page-head");

        let tab = safe_get(this.props, "match.params.tab", "forms");
        this.props.dispatch(updateFormsTab(tab));

        if (tab === 'analytics') {
            const query = window.location.search;
            if (query.includes('export=true')) {
                const [filterQuery, dateQuery] = this.splitExportQuery(query);
                if (filterQuery) {
                    this.props.dispatch(updateFormsFilterQuery(filterQuery));
                }
                if (dateQuery) {
                    this.props.dispatch(updateFormsPeriodQuery(dateQuery));
                }
            }
        }

        this.getDrafts();
    }

    reloadSubmissions = () => {
        this.context.events.emit(EventNames.REFRESH_DATA);
    }

    getMyDraftsLength = () => {
        let draftCountFromState = safe_get(this.state, "drafts", []).length;

        // if on regular forms page, use draft count from local cache
        if (this.state.page_type === "regular") {
            return this.context.caches.submissionDrafts?.regularDrafts?.length ?? draftCountFromState;
        }
        
        return draftCountFromState;
    }

    getCachedDrafts = async (query) => {
        if (query !== this.lastGetDraftsQuery) {
            const response = await get_form_drafts(query);
            const content = await response.json();
            this.lastGetDraftsQuery = query;
            this.lastGetDraftsResult = content;
        }

        return this.lastGetDraftsResult;
    }

    getDrafts() {
        this.setState({loadingDrafts: true});
        const filter = this.state.page_type === 'regular' ? 'regular' : 'lms';
        const query = `?form_types=${filter}`;
        this.getCachedDrafts(query).then(data => {
            const drafts = safe_get(data, 'drafts', []).sort(function (a, b) {
                return a.edited_at < b.edited_at ? 1 : -1
            });

            this.setState({
                drafts,
                loadingDrafts: false
            });
        }).catch(err => {
            NotificationAlert("error", "", "Unable to load your drafts.");
            this.setState({
                loadingDrafts: false
            });
        })
    }

    updateFormsTab = (tab) => {
        if (tab === this.props.activeTab) return;

        const tabInPath = this.props.match.params.tab;
        let newPath = '';

        if (tabInPath === undefined) {
            newPath = `${this.props.location.pathname}/${tab}`;
        } else {
            const pathSegments = this.props.location.pathname.split('/').slice(0, -1);
            pathSegments.push(tab);
            newPath = pathSegments.join('/');
        }

        this.props.history.push(newPath + this.props.location.search);
    }

    goToSubmitForm = (team_uuid, form_uuid) => {
        this.setState({showFormModal: false});
        this.props.history.push(`/${this.props.org_uuid}/home/team/${team_uuid}/${this.state.page_type === "lms" ? "courses" : "forms"}/${form_uuid}/submit`);
    }

    editDraft(draft) {
        this.props.history.push(`/${this.props.org_uuid}/home/team/${draft.team.uuid}/${this.state.page_type === "regular" ? "forms" : "courses"}/${draft.form.form_uuid}/submission/${draft.submission_uuid}`);
    }

    splitExportQuery(query) {
        let filters = query.substring(1).split("&");
        filters = filters.filter(function (n) {
            return n !== "" && n
        });

        const dateFilters = ['period', 'year', 'quarter', 'month', 'from_date', 'to_date']
        let dateQuery = '&';
        let filterQuery = '?';

        for (const i in filters) {
            const filter = filters[i].split("=");
            const filter_type = filter[0];
            const filter_value = filter[1] || "";

            if (dateFilters.includes(filter_type)) {
                dateQuery += `${filter_type}=${filter_value}&`;
            } else {
                filterQuery += `${filter_type}=${filter_value}&`;
            }
        }

        // remove trailing '&' and leading '?'
        dateQuery = dateQuery.substring(0, dateQuery.length - 1);
        // filterQuery = filterQuery.substring(1, filterQuery.length - 1);

        return [filterQuery, dateQuery];
    }

    exportAnalytics = () => {
        this.setState({isExportingAnalytics: true});

        let query = this.props.forms_query + this.props.period_query;

        if (query.length > 0 && query[0] === '&') {
            query = '?' + query.substring(1, query.length);
        } else if (query.length > 0 && query[0] !== '?') {
            query = '?' + query;
        }

        const body = JSON.stringify({query});

        if (window.location.hostname.includes('localhost')) {
            console.log('export analytics body', body);
        }

        if (this.state.page_type === "regular") {
            // console.log ('exporting forms')
            export_form_analytics(body).then(success => {
                success.json().then(success => {
                    if (success.data.public_url !== null) {
                        window.location = success.data.public_url;
                    } else {
                        // only happens when running against a local running back-end
                        console.log('export query', success.data.query);
                    }
                    this.setState({isExportingAnalytics: false});
                });
            }, error => {
                NotificationAlert('error', '', 'Could not export form analytics.');
                this.setState({isExportingAnalytics: false});
            });
        } else {
            // console.log ('exporting courses')
            export_courses_analytics(body).then(success => {
                success.json().then(success => {
                    if (success.data.public_url !== null) {
                        window.location = success.data.public_url;
                    } else {
                        // only happens when running against a local running back-end
                        console.log('export query for courses', success.data.query);
                    }
                    this.setState({isExportingAnalytics: false});
                });
            }, error => {
                NotificationAlert('error', '', 'Could not export course analytics.');
                this.setState({isExportingAnalytics: false});
            });
        }
    }

    renderTabActions = (isInline) => {
        const className = isInline ? "hidden-xs" : "visible-xs"
        const optionalMarginBottom = isInline ? {} : { marginBottom: '1rem' };
        return (
            <div className={className} >
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    { this.props.activeTab === 'forms' &&
                        <>
                            <div className='mar-rgt-10'>
                                <ResetSubmissionGridLink />
                            </div>
                            <SubmissionDataGridExportButton />
                            <ReloadOutlined
                                className="hover-cursor zero-blue mar-rgt-10 mar-lft-10"
                                style={{fontSize: '18px'}}
                                onClick={() => {this.reloadSubmissions()}}
                            />
                        </>
                    }
                    { this.props.activeTab === 'assignments' &&
                        <>
                            <DownloadOutlined
                                className="hover-cursor zero-blue"
                                style={{fontSize: "20px"}}
                                onClick={() => {
                                    if (this.tableRef.current) {
                                        exportTableToXlsx(this.tableRef.current, "ZERO Assignments Export.xlsx");
                                    }
                                }}
                            />
                            <ReloadOutlined
                                className="hover-cursor zero-blue mar-rgt-10 mar-lft-10"
                                style={{fontSize: '18px', ...optionalMarginBottom}}
                                onClick={() => {
                                    this.setState(state => ({
                                        formAssignmentsRefreshSignal: state.formAssignmentsRefreshSignal + 1
                                    }))
                                }}
                            />
                        </>
                    }
                    {this.props.activeTab === 'analytics' &&
                        <button
                            className="btn btn-discard btn-sm dont-print"
                            style={{width: '100px', ...optionalMarginBottom}}
                            disabled={this.state.isExportingAnalytics}
                            onClick={this.exportAnalytics}
                        >
                            { this.state.isExportingAnalytics ?
                                <ButtonLoading className="inverted"/> : 'Export to PDF'
                            }
                        </button>
                    }
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className={mainContentContainerClass() + ' print-7in'}>
                <CachedApiLoaderProvider>
                    <SubmissionDataGridContextProvider>

                        {
                            this.state.showFormModal &&
                            <SelectFormModal
                                show={this.state.showFormModal}
                                cancel={() => {
                                    this.setState({showFormModal: false});
                                }}
                                current_team_uuid={this.props.current_team_uuid}
                                confirm={this.goToSubmitForm}
                                teams={this.props.teams}
                                page_type={this.state.page_type}
                                location={this.props.location.pathname}
                            />
                        }
                        {
                            this.state.showMyDraftsModal &&
                            <MyDraftsModal
                                show={this.state.showMyDraftsModal}
                                cancel={() => {
                                    this.getDrafts();
                                    this.setState({showMyDraftsModal: false});
                                }}
                                editDraft={this.editDraft}
                                drafts={safe_get(this.state, "drafts", [])}
                                pageType={this.state.page_type}
                            />
                        }

                        <SubmissionDataGridModals />

                        <div id="page-head" className="no-padding-mobile no-padding-print">
                            <div className="row print-no-x-margins">
                                <div className="col-lg-12  col-xs-12 col-xs-offset-0 no-padding-print">
                                    <div id="page-title" style={{padding: '0px'}}>
                                        <div className="panel thin-border" style={{margin: '0 auto'}}>
                                            <div className="nav-header-panel no-padding-print">
                                                <div className="team-name-header">
                                                    <h3 className={"section-titles admin-page-header"}
                                                        style={{display: "inline-block", margin: "0px"}}>
                                                        {
                                                            this.state.page_type === "regular" ?
                                                                "Forms" :
                                                                "Courses"
                                                        }
                                                        {
                                                            this.state.page_type === "regular" &&
                                                            <InfoIconComponent
                                                                position={"bottom"}
                                                                width={"170px"}
                                                                text={<span>Anyone can submit a form, but only Admins and Team Leads can view all submissions.</span>}
                                                            />
                                                        }

                                                    </h3>
                                                    {
                                                        !isMobileApp() && !isViewer(this.props.user) &&
                                                        <button className="btn btn-primary pull-right dont-print" style={{
                                                            textAlign: 'center',
                                                            display: 'inline-block',
                                                            padding: "4px 6px 3px 6px"
                                                        }} onClick={() => {
                                                            this.setState({showFormModal: true})
                                                        }}>
                                                            <span className=""><PlusCircleOutlined className="mar-rgt-5"/>New Submission</span>
                                                        </button>
                                                    }
                                                    {
                                                        this.getMyDraftsLength() > 0 &&
                                                        <span className="blue-link mar-top-5 mar-rgt-10 pull-right dont-print"
                                                            onClick={() => {
                                                                this.setState({showMyDraftsModal: true});
                                                            }}>
                                                        My Drafts ({this.getMyDraftsLength()})
                                                    </span>
                                                    }
                                                </div>
                                                <p className="header" style={{
                                                    color: "#505050",
                                                    marginBottom: "0px",
                                                    paddingLeft: "2px",
                                                    paddingTop: "3px"
                                                }}>
                                                    {
                                                        this.state.page_type === "regular" ?
                                                            "Audits, checklists, inspections, and more." :
                                                            "Training, education, learning and more."
                                                    }
                                                </p>

                                                <div className="members-search" style={{
                                                    width: "100%",
                                                    display: (this.props.activeTab === "reports" ? "none" : "block")
                                                }}>
                                                    <FormsSearchFilter page_type={this.state.page_type}/>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    <div id="page-content" className="no-padding-mobile">
                        { this.state.page_type === "regular" &&
                            <SyncInProgressSpinner
                                style={{padding: "0 0 1rem 1rem"}}
                                postSyncCallback={() => this.reloadSubmissions()}
                            />
                        }
                        { this.state.page_type === "regular" && this.props.activeTab === "forms" &&
                            <SubmissionSyncErrorAlertList />
                        }
                        <BorderedPanel style={{padding: '0 1.5rem 1.5rem',}}>
                            <Tabs
                                destroyInactiveTabPane={true}
                                activeKey={this.props.activeTab}
                                onChange={newTab => {
                                    this.updateFormsTab(newTab);
                                }}
                                tabBarExtraContent={this.renderTabActions(true)}
                                renderTabBar={(props, DefaultTabBar) => (
                                    <>
                                        <DefaultTabBar {...props} />
                                        {this.renderTabActions(false)}
                                    </>
                                )}
                                items={[
                                    {
                                        key: 'forms',
                                        label: 'Submissions',
                                        children: (
                                            <div>
                                                <SubmissionDataGrid formType={this.state.page_type} />
                                            </div>
                                        )
                                    },
                                    {
                                        key: 'analytics',
                                        label: 'Analytics',
                                        children: (
                                            <div>
                                                <FormAnalyticsTab page_type={this.state.page_type}/>
                                            </div>
                                        )
                                    },
                                    {
                                        key: 'assignments',
                                        label: 'Assignments',
                                        children: (
                                            <div>
                                                <CachedApiLoaderContext.Consumer>
                                                {cachedApiLoader => (
                                                    <FormAssignmentsTab
                                                        page_type={this.state.page_type}
                                                        refreshSignal={this.state.formAssignmentsRefreshSignal}
                                                        cachedApiLoader={cachedApiLoader}
                                                        tableRef={this.tableRef}
                                                    />
                                                )}
                                                </CachedApiLoaderContext.Consumer>
                                            </div>
                                        )
                                    },
                                    (!isViewer(this.props.user)) && {
                                        key: 'reports',
                                        label: 'Reports',
                                        children: (
                                            <div>
                                                <GenerateReport
                                                    isIncidentsReport={false}
                                                    isFormsReport={this.state.page_type === "regular"}
                                                    isCoursesReport={this.state.page_type === "lms"}
                                                    page_type={this.state.page_type}
                                                />
                                            </div>
                                        )
                                    }
                                ]}
                            />
                        </BorderedPanel>
                    </div>

                        {
                            isMobileApp() && (isAdmin(this.props.user) || isTeamLead(this.props.user) || isUser(this.props.user) || isContributor(this.props.user)) &&
                            <div id="floating-button" style={{bottom: floatingButtonMargin()}} onClick={() => {
                                this.setState({showFormModal: true})
                            }}>
                                <p className="plus-sign">+</p>
                            </div>

                        }
                    </SubmissionDataGridContextProvider>
                </CachedApiLoaderProvider>
            </div>
        );

    }


}


const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", []),
        org_uuid: safe_get(store, "org_helper.organization.organization_uuid", ""),
        current_team_uuid: safe_get(store, "teams_helper.team.uuid", ""),
        teams: safe_get(store, "teams_helper.teams", []),
        forms_query: safe_get(store, "forms.query", ""),
        period_query: safe_get(store, "forms.period_query", ""),
        page_position: safe_get(store, "forms.position", 0),
        pagination: safe_get(store, "forms.pagination", 1),
        activeTab: safe_get(store, "forms.tab", "forms"),
    }
}

export default withRouter(connect(mapStateToProps)(Forms));
  
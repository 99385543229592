import React, {Component} from 'react';
import {connect} from 'react-redux'
import {withRouter} from 'react-router';

import {Modal, Checkbox, Radio, Select} from 'antd';

import {
    get_share_form_options,
    share_form,
    update_form,
    get_forms_filters,
    make_form_copy,
    getTeamsList
} from '../../../api/zero-api.js'
import {isAdmin, safe_get} from '../../../other/Helper';

import NotificationAlert from '../../../other/NotificationAlert';

import Skeleton from 'react-loading-skeleton';
import ButtonLoading from '../../Shared/ButtonLoading';
import LoadingIndicator from '../../Shared/LoadingIndicator';
import InfoIconComponent from '../../InfoIconComponent';

import '../../../assets/css/antd-custom.css';
import { FormType } from 'other/Constants.js';

class FormOptionsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            categories: [],
            loading_teams: this.props.form_to_edit ? true : false,
            form_name: "",
            selected_form_type: this.props.forceFormType ?? undefined,
            selected_teams: [],
            teams_options: this.props.teams,
            admin_restricted: (this.props.form_to_edit && this.props.form_to_edit.admin_restricted) || false,
            restrict_user_edits: (this.props.form_to_edit && this.props.form_to_edit.restrict_user_edits) || false,
        };

        this.isRegular = !this.props.forceFormType;
        this.isIncident = this.props.forceFormType === FormType.INCIDENT;
        this.isCourse = this.props.forceFormType === FormType.LMS;
        this.isPost = this.props.forceFormType === FormType.POST;

        this.showPermissions = this.isRegular || this.isCourse;
        this.showCategory = this.isRegular || this.isCourse;

        this.formNameChange = this.formNameChange.bind(this)
        this.formTypeChange = this.formTypeChange.bind(this);
        this.teamSelect = this.teamSelect.bind(this);
        this.selectAllTeams = this.selectAllTeams.bind(this);
        this.selectNoTeams = this.selectNoTeams.bind(this);
        this.shareForm = this.shareForm.bind(this);
        this.handleButton = this.handleButton.bind(this);
    }

    componentDidMount() {
        var self = this;

        if (this.props.form_to_edit || this.props.formToCopy) {
            const sourceForm = this.props.form_to_edit || this.props.formToCopy;
            get_share_form_options(sourceForm.form_uuid).then(function (success) {
                success.json().then(success => {
                    var teams = safe_get(success, "teams", []).sort((a, b) => {
                        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                    });

                    var selected_teams = []
                    for (var i in teams) {
                        var team = teams[i];
                        if (team.shared) {
                            selected_teams.push(team.team_uuid)
                        }
                    }

                    self.setState({
                        loading_teams: false,
                        selected_teams: selected_teams,
                        teams_options: teams
                    });
                });

            }, function (error) {
                console.log(error)
            });

            let formName = sourceForm.name;
            if (this.props.formToCopy) {
                formName = `Copy of ${formName}`;
            }

            this.setState({
                form_name: formName,
                selected_form_type: sourceForm.schedule_only ? "1" : sourceForm.form_type.toString(),
                selected_category: safe_get(sourceForm, "category.category_uuid", undefined)
            });
        }

        if (isAdmin(this.props.user)) {
            getTeamsList()
                .then((teams) => {
                    self.setState({
                        teams_options: teams
                    });
                })
                .catch((error) => {});
        }

        get_forms_filters("").then(function (success) {
            success.json().then(success => {
                let categories = safe_get(success, "categories", []);
                self.setState({categories: categories});
            });
        });
    }

    formNameChange(e) {
        this.setState({form_name: e.target.value});
    }

    formTypeChange(e) {
        this.setState({selected_form_type: e.target.value});
    }

    teamSelect(e) {
        let team_uuid = e.target.value;
        var selected_teams_clone = [...this.state.selected_teams];
        var index = selected_teams_clone.indexOf(team_uuid);

        if (index >= 0) {
            selected_teams_clone.splice(index, 1);
        } else {
            selected_teams_clone.push(team_uuid);
        }

        this.setState({
            selected_teams: selected_teams_clone,
        });
    }

    handleTeamsSelect = (value) => {
        this.setState({selected_teams: value});
    }

    selectAllTeams() {
        var selected_teams = [...this.state.teams_options].map(team => team.uuid || team.team_uuid);
        this.setState({selected_teams: selected_teams})
    }

    selectNoTeams() {
        this.setState({selected_teams: []});
    }

    handleButton() {
        this.setState({saving: true});

        if (this.props.team_access) {
            this.shareForm();
        } else if (this.props.form_to_edit) { // EDITING FORM SETTINGS
            if (this.state.selected_form_type !== "2") {
                this.shareForm();
            }
        } else if (this.props.formToCopy) { // Copying existing form
            let formType = parseInt(this.state.selected_form_type, 10);
            let scheduleOnly = false;
            if (formType === 1) {
                formType = 0;
                scheduleOnly = true;
            }

            const body = JSON.stringify({
                form_uuid: this.props.formToCopy.form_uuid,
                team_uuids: (this.state.selected_form_type === 2 ? [] : this.state.selected_teams) ?? [],
                form_type: formType,
                schedule_only: scheduleOnly,
                enabled: true,
                name: this.state.form_name,
                admin_restricted: this.state.admin_restricted,
                restrict_user_edits: this.state.restrict_user_edits,
            })
            make_form_copy(this.props.formToCopy.form_uuid, body)
            .then(() => {
                this.props.refresh();
                NotificationAlert("success", "", "Form copied.");
            })
            .catch((error) => {
                console.error(error);
                NotificationAlert("error", "", "Unable to make form copy.")
            })
            .finally(() => {
                this.props.cancel();
            });
        } else if (this.props.complete) { // CREATING FORM
            this.props.complete();

            const query = new URLSearchParams();
            query.set('form_title', this.state.form_name);
            query.set('form_type', this.state.selected_form_type);
            query.set('selected_teams', this.state.selected_teams);
            query.set('category_uuid', this.state.selected_category);
            query.set('admin_restricted', this.state.admin_restricted);
            query.set('restrict_user_edits', this.state.restrict_user_edits);

            const orgId = this.props.organization.organization_uuid;

            if (this.state.page_type === 'lms') {
                this.props.history.push(`/${orgId}/home/manage_courses/new_form?${query}`);
            } else if (this.state.page_type === 'incident') {
                this.props.history.push(`/${orgId}/home/manage_incident_templates/new_form?${query}`);
            } else if (this.props.forceFormType === FormType.POST) {
                this.props.history.push(`/${orgId}/home/manage_post_templates/new_form?${query}`);
            } else {
                this.props.history.push(`/${orgId}/home/manage_forms_templates/new_form?${query}`);
            }
        }
    }

    updateForm = () => {
        var self = this;

        this.setState({updating: true});

        let schedule_only = false;
        let form_type = this.state.selected_form_type;
        if (form_type === "1") {
            form_type = "0";
            schedule_only = true;
        }
        var body = JSON.stringify({
            name: this.state.form_name,
            form_type: parseInt(form_type),
            // fields: safe_get(this.props, "form_to_edit.fields", []),
            enabled: safe_get(this.props, "form_to_edit.enabled", true),
            schedule_only: schedule_only,
            category_uuid: this.state.selected_category,
            admin_restricted: this.state.admin_restricted,
            restrict_user_edits: this.state.restrict_user_edits
        });

        update_form(this.props.form_to_edit.form_uuid, body).then(function (success) {
            setTimeout(() => {
                self.props.refresh();
                self.props.cancel();
                NotificationAlert("success", "", "Template updated.");
            }, 1000);
        }, function (error) {
            self.props.cancel();
            NotificationAlert("error", "", "Unable to update form.");
        });
    }

    shareForm() {
        var self = this;
        var body = JSON.stringify({
            team_uuids: this.state.selected_teams
        });

        share_form(this.props.form_to_edit.form_uuid, body).then(function (success) {
            if (self.props.team_access) {
                self.props.cancel();
                self.props.refresh();
                NotificationAlert("success", "", "Team access updated.");
            } else if (self.props.form_to_edit) {
                self.updateForm();
            }
        }, function (error) {
            if (self.props.team_access) {
                self.props.cancel();
                NotificationAlert("success", "", "Could not update team access.");
            }
        });
    }

    getModalTitle = () => {
        if (this.props.team_access) {
            return "Manage Access";
        } else if (this.props.form_to_edit) {
            return "Edit Settings";
        } else if (this.props.formToCopy) {
            return this.isCourse ? "Copy Course" : "Copy Template";
        } else {
            return this.isCourse ? "Create Course" : "Create Template";
        }
    }

    getConfirmButtonElement = () => {
        if (this.state.saving) {
            return <ButtonLoading />
        } else if (this.props.form_to_edit) {
            return "Save";
        } else if (this.props.formToCopy) {
            return "Copy";
        } else {
            return "Create";
        }
    }

    render() {

        let showType = true;
        if (this.props.form_to_edit && this.state.selected_form_type === "2") {
            showType = false;
        } else if (this.props.team_access) {
            showType = false;
        }

        if (!this.isRegular) {
            showType = false
        }

        let disableCreate = (
            this.state.form_name.length === 0
            || !this.state.selected_form_type
            || this.state.updating
            || (!this.props.form_to_edit && !(this.isIncident || this.isPost) && this.state.selected_teams.length === 0)
        );

        return (
            (<Modal
                title={this.getModalTitle()}
                open={this.props.show}
                maskClosable={false}
                onCancel={this.props.cancel}
                maskTransitionName="maskTransitionName"
                footer={
                    <div>
                        <button className="btn btn-discard" onClick={this.props.cancel}>
                            Cancel
                        </button>
                        <button className="btn btn-modal" onClick={this.handleButton} disabled={disableCreate}>
                            {this.getConfirmButtonElement()}
                        </button>
                    </div>
                }
            >
                {
                    !this.props.team_access &&
                    <div className="" style={{marginTop: "0px", marginBottom: "15px"}}>
                        <h3 className="titles" style={{
                            marginBottom: '2px',
                            marginLeft: '0px',
                            fontSize: '110%',
                            marginTop: "0px"
                        }}>Name</h3>
                        <input
                            type={"text"}
                            id={"form-name"}
                            placeholder={this.isIncident ? "e.g. Incident Report Template" : this.isCourse ? "e.g. EHS Training Course" : "e.g. Site Audit Form"}
                            className={"form-control custom-placeholder"}
                            value={this.state.form_name}
                            onChange={this.formNameChange}
                        />
                    </div>
                }
                {
                    showType &&
                    <div className="" style={{marginTop: "0px"}}>
                        <h3 className="titles"
                            style={{marginBottom: '2px', marginLeft: '0px', fontSize: '110%', marginTop: "0px"}}>
                            Type
                            <InfoIconComponent
                                position={"bottom"}
                                width={"155px"}
                                text={<span><a rel="noopener noreferrer"
                                               onClick={() => FreshworksWidget('open', 'article', {'id': 70000407344})}
                                               className="blue-link">Learn more</a> about form types.</span>}
                            />
                        </h3>
                        {
                            !this.state.loading &&
                            <div className="form-group">
                                {
                                    !this.isIncident &&
                                    <div>
                                        <div className="form-check" style={{minHeight: "25px"}}>
                                            {/* <input className="form-radio-input" type="radio" id="form_type_regular" value={0} checked={this.state.selected_form_type === "0"} onChange={this.formTypeChange} />
                      <label className="form-radio-label zero-dark-grey" style={{ marginLeft: "5px", verticalAlign: "1px" }} htmlFor="form_type_regular">
                        Regular — can be submitted ad hoc or via schedule.
                      </label> */}
                                            <Radio value={"0"} checked={this.state.selected_form_type === "0"}
                                                   onChange={this.formTypeChange}>
                                                Regular — can be submitted ad hoc or via schedule.
                                            </Radio>
                                        </div>
                                        <div className="form-check" style={{minHeight: "25px"}}>
                                            {/* <input className="form-radio-input" type="radio" id="form_type_schedule" value={1} checked={this.state.selected_form_type === "1"} onChange={this.formTypeChange} />
                      <label className="form-radio-label zero-dark-grey" style={{ marginLeft: "5px", verticalAlign: "1px" }} htmlFor="form_type_schedule">
                        Schedule Only — can only be submitted via schedule.
                      </label> */}
                                            <Radio value={"1"} checked={this.state.selected_form_type === "1"}
                                                   onChange={this.formTypeChange}>
                                                Schedule Only — can only be submitted via schedule.
                                            </Radio>
                                        </div>
                                    </div>
                                }
                                {
                                    !this.props.form_to_edit && this.isIncident &&
                                    <div className="form-check" style={{minHeight: "25px"}}>
                                        {/* <input className="form-radio-input" type="radio" id="form_type_incident" value={2} checked={true} onChange={this.formTypeChange} />
                    <label className="form-radio-label zero-dark-grey" style={{ marginLeft: "5px", verticalAlign: "1px" }} htmlFor="form_type_incident">
                      Incident
                    </label> */}
                                        <Radio value={2} checked={true} onChange={this.formTypeChange}>
                                            Incident
                                        </Radio>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                }
                {!this.props.team_access && this.showPermissions && isAdmin(this.props.user) &&
                    <div style={{marginBottom: "15px"}}>
                        <h3 className="titles" style={{
                            marginBottom: '2px',
                            marginLeft: '0px',
                            fontSize: '110%',
                            marginTop: "0px"
                        }}>Permissions</h3>
                        <div>
                            <Checkbox checked={this.state.admin_restricted}
                                      onChange={e => this.setState({admin_restricted: e.target.checked})}>Restrict
                                access to Admins only
                                <InfoIconComponent
                                    position={"bottom"}
                                    width={"250px"}
                                    text={<span>If this box is checked, only admins can view and edit submissions for this template. Team leads and below will see only their own submissions, schedules, and assignments that use this template</span>}
                                />
                            </Checkbox>
                        </div>
                        <div>
                            <Checkbox checked={this.state.restrict_user_edits}
                                      onChange={e => this.setState({restrict_user_edits: e.target.checked})}>Restrict
                                members/contributors from editing and deleting
                                <InfoIconComponent
                                    position={"bottom"}
                                    width={"250px"}
                                    text={<span>If this box is checked, then members and contributors will not be able to edit or delete their submissions once they are submitted.</span>}
                                />
                            </Checkbox>
                        </div>
                    </div>
                }
                {
                    this.showCategory &&
                    <div className="" style={{marginTop: "0px"}}>
                        {
                            this.state.categories.length > 0 && !this.isCourse && !this.props.team_access &&
                            <div style={{marginBottom: "15px"}}>
                                <h3 className="titles" style={{
                                    marginBottom: '2px',
                                    marginLeft: '0px',
                                    fontSize: '110%',
                                    marginTop: "0px"
                                }}>Category</h3>
                                <Select
                                    mode=""
                                    placeholder={"Select a category"}
                                    style={{width: "100%"}}
                                    showArrow={true}
                                    virtual={false}
                                    value={this.state.selected_category}
                                    onChange={(value) => {
                                        this.setState({selected_category: value});
                                    }}
                                    dropdownStyle={{zIndex: "10000"}}
                                >
                                    {
                                        this.state.categories.map((category, index) => (
                                            <Select.Option key={`0:${index}`}
                                                           value={category.category_uuid}>{category.category_name}</Select.Option>
                                        ))
                                    }
                                </Select>
                            </div>
                        }
                        {
                            !this.props.team_access &&
                            <h3 className="titles"
                                style={{marginBottom: '2px', marginLeft: '0px', fontSize: '110%', marginTop: "0px"}}>
                                Team Access
                                <InfoIconComponent
                                    position={"bottom"}
                                    width={"250px"}
                                    text={<span>The list of teams that can submit this template.</span>}
                                />
                            </h3>
                        }
                        <div className="form-group" style={{overflow: "auto", marginBottom: "0px"}}>
                            <span className="blue-link" onClick={this.selectAllTeams}>Select All</span> &bull; <span
                            className="blue-link" onClick={this.selectNoTeams}>Select None</span>
                            <div className="subscribers-checkbox" style={{marginTop: "8px", overflow: "auto"}}>
                                {
                                    this.state.loading_teams &&
                                    [...Array(10)].map((_item, index) => (
                                        <div key={index} className="form-check" style={{minHeight: "25px"}}>
                                            <Skeleton width={100}/>
                                        </div>
                                    ))
                                }
                                {
                                    !this.state.loading_teams && this.props.form_to_edit &&
                                    this.state.teams_options.map((team, index) => (
                                        <div key={index} className="form-check"
                                             style={{minHeight: "25px", overflow: "auto", wordBreak: 'keep-all'}}>
                                            <Checkbox key={index}
                                                      id={"team_val_" + index}
                                                      value={team.team_uuid || team.uuid}
                                                      checked={this.state.selected_teams.indexOf(team.team_uuid) >= 0 || this.state.selected_teams.indexOf(team.uuid) >= 0}
                                                      onChange={this.teamSelect}
                                            >
                                                {team.name}
                                            </Checkbox>
                                        </div>
                                    ))
                                }
                                {
                                    !this.state.loading_teams && !this.props.form_to_edit &&
                                    this.state.teams_options.map((team, index) => (
                                        <div key={index} className="form-check"
                                             style={{height: "25px", overflow: "auto", wordBreak: 'keep-all'}}>
                                            <Checkbox key={index}
                                                      id={"team_val_" + index}
                                                      value={team.team_uuid || team.uuid}
                                                      checked={this.state.selected_teams.indexOf(team.uuid) >= 0 || this.state.selected_teams.indexOf(team.team_uuid) >= 0}
                                                      onChange={this.teamSelect}
                                            >
                                                {team.name}
                                            </Checkbox>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                }
            </Modal>)
        );
    }
}

const mapStateToProps = store => {
    return {
        teams: safe_get(store, "teams_helper.teams", []),
        user: safe_get(store, "user.user", {}),
        organization: safe_get(store, "org_helper.organization", {})
    }
}

export default withRouter(connect(mapStateToProps)(FormOptionsModal));